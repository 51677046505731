//font size
//sp
$f_size_sp: 1.4rem;

//pc
$f_size_pc: 1.6rem;

//font family
@font-face {
  font-family: YuMincho;
  src: local(Yu Mincho),
  local(YuMincho);
  font-weight: normal;
}
$f_base: 'Noto Sans JP', YuGothicM, YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif";
$f_serif: 'Noto Serif JP', "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
$f_en: 'Vidaloka', 'Noto Serif JP', serif;

// color scheme //
// base
$c_text: #2c2c2c;
$c_text_gray: #666;
$c_error: #ED5454;
$c_border: #D9D9D9;

// lc-components
$c_base: #fff;
$c_main: #85b098;
$c_text_accent: $c_base;
$c_text_main: $c_base;
$c_base_gray: #F3F3F3;
$c_main_bg: rgba(0,89,79,0.1);
$c_accent: #85b098;
$c_sns_twitter: #1DA1F2;
$c_sns_facebook: #4267B2;

